@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.App {
  text-align: center;
}

.navbar {
  background-color: white;
}

.navbar:hover {
  opacity: 100%;
}

.navbarLinks {
  justify-content: center;
}

.navLink {
  position: relative;
  text-align: start;
  text-align-last: center;
}

.link {
  font-size: 1.3rem;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #343a40;
  font-weight: 500;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 300ms ease-in;
}

.link:hover {
  text-decoration-color: #343a40;
  color: #343a40;
}

.active {
  text-decoration: underline;
}

.header {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('./img/arch-bridge.jpg');
  background-size: cover;
  height: auto;

  width: 100%;
}

.homePageLogo {
  padding: 10rem;
}

.mainLogo {
  opacity: 1;
  max-width: 90%;
  max-height: 380px;
}

.titleContainer {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 60px;
  margin-top: 2rem;
}

.subtitle {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #343a40;
  font-weight: 400;
}

.homepageQuote {
  color: white;
  font-size: 35px;
  text-shadow: 2px 2px 10px black;
  padding-bottom: 6rem;
  margin-bottom: 0;
}

.featured {
  padding-top: 14rem;
  padding-bottom: 16rem;
  height: auto;
  background-color: #edf6f9;
}

.reviewSection {
  background-color: #a8dadc;
  height: auto;
}

.reviewHead {
  margin-right: 2rem;
  margin-left: 2rem;
}

.reviewTitle {
  margin-top: 2rem;
}

.card-reviews {
  width: 20rem;
  height: 25rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.card-icon {
  font-size: 30px;
  padding-top: 1rem;
}

.card-description {
  margin-top: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-bottom: 4rem;
}

.about {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url('img/juliosunset.jpg');
  background-position: center;
  background-size: cover;
  height: auto;
  padding-bottom: 4rem;
}

.aboutMeTitle {
  color: white;
  text-decoration: underline;
  font-family: 'Secular One', sans-serif;
  font-size: 50px;
  margin-bottom: 3rem;
  font-weight: 500;
}

.pageLinks {
  margin-bottom: 2rem;
  padding-left: 30px !important;
}

.uebtn {
  width: 250px;
  height: 35px;
  text-decoration: none;
  line-height: 30px;
  border: 1px solid;
  border-color: #FFFFFF;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6) !important;
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
.uebtn:hover, .uebtn:focus {
  background-color: #FFFFFF !important;
  color: #403D39 !important;
  border-color: #FFFFFF !important;
}

.ratebtn {
  width: 250px;
  height: 35px;
  text-decoration: none;
  line-height: 30px;
  border: 1px solid;
  border-color: #FFFFFF;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6) !important;
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}

.ratebtn:hover, .ratebtn:focus {
  background-color: #FFFFFF !important;
  color: #403D39 !important;
  border-color: #FFFFFF !important;
}

.citbtn {
  width: 250px;
  height: 35px;
  text-decoration: none;
  line-height: 30px;
  border: 1px solid;
  border-color: #FFFFFF;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6) !important;
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}

.citbtn:hover, .citbtn:focus {
  background-color: #FFFFFF !important;
  color: #403D39 !important;
  border-color: #FFFFFF !important;
}

.aboutSection {
  margin-top: 2rem;
  padding-top: 10rem;
}

.aboutPhoto {
  display: flex;
  justify-content: center;
}

.aboutInfo {
  color: white;
  text-shadow: 1px 1px 15px black;
  margin: 1rem;
  font-size: 24px;
}

.aboutJuliobg {
  background-color: rgba(0, 0, 0, 0.6);
  height: 555px;
  width: 460px;
  border-radius: 20px;
}

.picOfJulio {
  border-radius: 20px;
  
  margin: 2rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.25);
}

.aboutLinkedBtn {
  color: #0976b4 !important;
  background-color: white !important;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 100px;
}

.aboutLinkedBtn:hover,
.aboutLinkedBtn:focus,
.aboutLinkedBtn:active {
  color: white !important;
  background-color: #0976b4 !important;
}

.aboutBtnLogo {
  font-size: 20px;
  vertical-align: top;
}

.aboutName {
  color: white;
  font-size: 30px;
  text-decoration: underline;
}

.connect {
  color: white;
}

.UniqueExperience {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url('img/pexels-bella-white-635279.jpg');
  background-attachment: fixed;
  background-size: cover;
  height: auto;
}

.CommonInsuranceTerms {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url('img/oregon-coast.JPG');
  background-attachment: fixed;
  background-size: cover;
  height: auto;
}

.RatesPage {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url('img/bg22.jpg');
  background-attachment: fixed;
  background-size: cover;
  height: auto;
}

.rAndIpage {
  padding-bottom: 6rem;
}

.rAndI {
  font-size: 18px;
  text-align: start;
  margin: 1rem;
  color: white;
  text-shadow: 1px 1px 10px black;
}

.rAndITitle {
  color: white;
  font-size: 40px;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
  padding-top: 10rem;
  text-decoration: underline;
  text-shadow: 1px 1px 10px black;
}

.featuredInfo {
  padding: 5rem;
  font-size: 20px;
}

.featuredIcon {
  font-size: 5rem;
  color: #1d3557;
}

h3 {
  padding: 0.5rem;
}

.featuredDescription {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.contactTitle {
  text-decoration: underline;
  margin-bottom: 2rem;
}

.contactus {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url('img/lakepick.jpg');
  background-position: center;
  background-size: cover;
  height: auto;
  display: flex;
  justify-content: center;
}

.card-contact {
  margin-top: 12rem;
  margin-bottom: 12rem;
  border-radius: 20px;
  padding: 25px;
  background-color: #ffffff;
  border: 0;
  width: 800px;
}

.contactPhone {
  color: black;
}

.phoneIcon {
  font-size: 4rem;
  color: #457b9d;
}

.arrowIcon {
  font-size: 3rem;
  color: #457b9d;
  line-height: 0.5;
}

.contact-phone {
  font-size: 20px;
  padding-bottom: 3rem;
}

.nameLabel {
  font-size: 18px;
}

.nameArea {
  border-radius: 5px;
  border: 2px solid #457b9d;
  width: 220px;
  margin-bottom: .5rem;
}

.emailLabel {
  font-size: 18px;
}

.emailArea {
  border-radius: 5px;
  border: 2px solid #457b9d;
  width: 220px;
  margin-bottom: .5rem;
}

.messageLabel {
  font-size: 18px;
}

.messageArea {
  border-radius: 5px;
  border: 2px solid #457b9d;
  height: 120px;
  width: 220px;
}

.contactSubmitBtn {
  background-color: #a8dadc;
}

.contactSubmitBtn:hover,
.contactSubmitBtn:active,
.contactSubmitBtn:visited,
.contactSubmitBtn:focus {
  background-color: #457b9d;
}

.btn-primary {
  background-color: #457b9d !important;
  border-color: #457b9d !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #a8dadc !important;
  border-color: #457b9d !important;
  color: black !important;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #343a40;
  color: white;
  height: auto;
}

.footerLinks {
  justify-content: center;
}

.footLink {
  color: white;
  text-decoration: none;
  padding: 1rem;
}

.footLink:hover,
.footLink:active {
  color: white;
  text-decoration: underline;
}

.umhcaLink {
  padding: 0 !important;
}

.umhcaImage {
  background-color: white;
}

.twitterbtn {
  font-size: 25px;
}

.facebookbtn {
  font-size: 25px;
}

.googleplusbtn {
  font-size: 26px;
}

.linkedinbtn {
  font-size: 25px;
}

.btn-just-icon {
  border-radius: 50px;
  height: 50px;
  width: 50px;
  min-width: 40px;
  padding: 8px;
  padding: 4px !important;
  font-size: 16px;
  padding: 2px 0px;
}

.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #ffffff;
  opacity: 1;
}
.btn-facebook.btn-border {
  border-color: #3b5998;
  color: #3b5998;
  border-radius: 50px;
}
.btn-facebook.btn-border:hover,
.btn-facebook.btn-border:focus,
.btn-facebook.btn-border:active,
.btn-facebook.btn-border.active,
.open > .btn-facebook.btn-border.dropdown-toggle {
  background-color: #3b5998;
  border-color: #3b5998;
  color: rgba(255, 255, 255, 0.7);
}
.btn-facebook.btn-border:disabled,
.btn-facebook.btn-border[disabled],
.btn-facebook.btn-border.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-link {
  color: #3b5998;
  opacity: 0.8;
}
.btn-facebook.btn-link:hover,
.btn-facebook.btn-link:focus,
.btn-facebook.btn-link:active,
.btn-facebook.btn-link.active,
.open > .btn-facebook.btn-link.dropdown-toggle {
  background-color: transparent !important;
  color: #3b5998;
  opacity: 1;
  border-color: transparent !important;
}
.btn-facebook.btn-link:hover i,
.btn-facebook.btn-link:focus i,
.btn-facebook.btn-link:active i,
.btn-facebook.btn-link.active i,
.open > .btn-facebook.btn-link.dropdown-toggle i {
  color: #3b5998;
  opacity: 1;
}

.btn-twitter {
  color: #ffffff;
  background-color: #55acee;
  border-color: #55acee;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: #55acee !important;
  border-color: #55acee !important;
  color: #ffffff;
  opacity: 1;
}
.btn-twitter.btn-border {
  border-color: #55acee;
  color: #55acee;
  border-radius: 50px;
}
.btn-twitter.btn-border:hover,
.btn-twitter.btn-border:focus,
.btn-twitter.btn-border:active,
.btn-twitter.btn-border.active,
.open > .btn-twitter.btn-border.dropdown-toggle {
  background-color: #55acee;
  border-color: #55acee;
  color: rgba(255, 255, 255, 0.7);
}
.btn-twitter.btn-border:disabled,
.btn-twitter.btn-border[disabled],
.btn-twitter.btn-border.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-link {
  color: #55acee;
  opacity: 0.8;
}
.btn-twitter.btn-link:hover,
.btn-twitter.btn-link:focus,
.btn-twitter.btn-link:active,
.btn-twitter.btn-link.active,
.open > .btn-twitter.btn-link.dropdown-toggle {
  background-color: transparent !important;
  color: #55acee;
  opacity: 1;
  border-color: transparent !important;
}
.btn-twitter.btn-link:hover i,
.btn-twitter.btn-link:focus i,
.btn-twitter.btn-link:active i,
.btn-twitter.btn-link.active i,
.open > .btn-twitter.btn-link.dropdown-toggle i {
  color: #55acee;
  opacity: 1;
}

.btn-google {
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #dd4b39;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-google:hover,
.btn-google:focus,
.btn-google:active,
.btn-google.active,
.open > .btn-google.dropdown-toggle {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #ffffff;
  opacity: 1;
}
.btn-google.btn-border {
  border-color: #dd4b39;
  color: #dd4b39;
  border-radius: 50px;
}
.btn-google.btn-border:hover,
.btn-google.btn-border:focus,
.btn-google.btn-border:active,
.btn-google.btn-border.active,
.open > .btn-google.btn-border.dropdown-toggle {
  background-color: #dd4b39;
  border-color: #dd4b39;
  color: rgba(255, 255, 255, 0.7);
}
.btn-google.btn-border:disabled,
.btn-google.btn-border[disabled],
.btn-google.btn-border.disabled {
  background-color: transparent;
  border-color: #dd4b39;
}
.btn-google.btn-link {
  color: #dd4b39;
  opacity: 0.8;
}
.btn-google.btn-link:hover,
.btn-google.btn-link:focus,
.btn-google.btn-link:active,
.btn-google.btn-link.active,
.open > .btn-google.btn-link.dropdown-toggle {
  background-color: transparent !important;
  color: #dd4b39;
  opacity: 1;
  border-color: transparent !important;
}
.btn-google.btn-link:hover i,
.btn-google.btn-link:focus i,
.btn-google.btn-link:active i,
.btn-google.btn-link.active i,
.open > .btn-google.btn-link.dropdown-toggle i {
  color: #dd4b39;
  opacity: 1;
}

.btn-linkedin {
  color: #ffffff;
  background-color: #0976b4;
  border-color: #0976b4;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-linkedin:hover,
.btn-linkedin:focus,
.btn-linkedin:active,
.btn-linkedin.active,
.open > .btn-linkedin.dropdown-toggle {
  background-color: #0976b4 !important;
  border-color: #0976b4 !important;
  color: #ffffff;
  opacity: 1;
}
.btn-linkedin.btn-border {
  border-color: #0976b4;
  color: #0976b4;
  border-radius: 50px;
}
.btn-linkedin.btn-border:hover,
.btn-linkedin.btn-border:focus,
.btn-linkedin.btn-border:active,
.btn-linkedin.btn-border.active,
.open > .btn-linkedin.btn-border.dropdown-toggle {
  background-color: #0976b4;
  border-color: #0976b4;
  color: rgba(255, 255, 255, 0.7);
}
.btn-linkedin.btn-border:disabled,
.btn-linkedin.btn-border[disabled],
.btn-linkedin.btn-border.disabled {
  background-color: transparent;
  border-color: #0976b4;
}
.btn-linkedin.btn-link {
  color: #0976b4;
  opacity: 0.8;
}
.btn-linkedin.btn-link:hover,
.btn-linkedin.btn-link:focus,
.btn-linkedin.btn-link:active,
.btn-linkedin.btn-link.active,
.open > .btn-linkedin.btn-link.dropdown-toggle {
  background-color: transparent !important;
  color: #0976b4;
  opacity: 1;
  border-color: transparent !important;
}
.btn-linkedin.btn-link:hover i,
.btn-linkedin.btn-link:focus i,
.btn-linkedin.btn-link:active i,
.btn-linkedin.btn-link.active i,
.open > .btn-linkedin.btn-link.dropdown-toggle i {
  color: #0976b4;
  opacity: 1;
}

.label-facebook {
  background-color: #3b5998;
}

.label-twitter {
  background-color: #55acee;
}

.label-linkedin {
  background-color: #0976b4;
}

.icon-facebook {
  color: #3b5998;
}

.icon-twitter {
  color: #55acee;
}

.icon-linkedin {
  color: #0976b4;
}

.legalName {
  margin-top: 2rem;
}

.thankyou {
  background-color: #457b9d;
  height: 50rem;
  padding-top: 20rem;
}

.thankyouTitle {
  font-size: 40px;
}

.thankyouMessage {
  font-size: 20px;
}

.thankyouLink {
  background-color: white;
}

.phoneLogo {
  font-size: 20px;
}

.footerEmail {
  margin-left: 1rem;
}

.emailLogo {
  font-size: 20px;
}

